@charset "UTF-8";
/* dev styles */
.dev-highlight {
	background: #add8e6;
	display: inline-block;
	font-family: Verdana, sans-serif;
	font-size: 26px;
	margin: 30px 0 10px;
}

.dev-highlight-content {
	background: #fdf2f3;
}

.dev-highlight-error {
	background: #c30;
	color: rgba(255, 255, 255, 1);
}

.dev-block {
	display: block;
}

.dev-highlight-head {
	background: #ffc0cb;
	display: block;
	margin-top: 50px;
}

.dev-component-headline {
	background-color: #f00;
	color: #000;
	display: inline-block;
	font: 20px Verdana, sans-serif;
	margin-bottom: 20px;
	padding: 5px;
}

.dev-component-border {
	border: 1px dotted #add8e6;
	box-sizing: border-box;
}

.dev-todo {
	background: #f90;
}

.dev-container-flex {
	display: flex;
}

.dev-container {
	background: #ccc;
	padding: 1%;
}

.dev-container--with-side-padding {
	padding-left: var(--space-column);
	padding-right: var(--space-column);
}

.dev-container-border {
	background: transparent;
	border: 1px dotted #000;
	margin-bottom: 1%;
}

.dev-container-inverted,
.dev-container[data-theme="dark"] {
	background-color: #333;
	margin-bottom: 1%;
}

.dev-wrapper {
	background: #ffc0cb;
}

.dev-inline-separator {
	display: inline-block;
	width: var(--space-xl);
}

p.dev-highlight {
	font-size: 2rem;
}

.dev-hr {
	margin: 60px 0;
}
